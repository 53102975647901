import React from "react";
import PortfolioSingle from "../components/PortfolioSingle";
import PortfolioCategory from "../components/PortfolioCategory";
import Fade from 'react-reveal/Fade';
import "../styles.css"
const Portfolio = () => {

return (
  
<div className="portfolio">
  <section className="sect-portfolio"><Fade>
  <h1>PORTFOLIO</h1>
  </Fade><Fade>
<PortfolioSingle />
</Fade>
</section>
<div className="categoryholder">
<h1>Web:</h1>
<PortfolioCategory category="web"/>
</div><div className="categoryholder">
<h1>Branding:</h1>
<PortfolioCategory category="branding"/>
</div><div className="categoryholder">
<h1>Print:</h1>
<PortfolioCategory category="print"/>
</div>
 </div>
  )

};

  export default Portfolio;