import React from "react";
import PortfolioItem from "./PortfolioItem";
import {data} from "./Data";

const PortfolioCategory = (props) => {

const filteredArray = data.filter(item => item.category === props.category )


return(
    
    <>
    


    <PortfolioItem cases={filteredArray} />

    </>
)

}


export default PortfolioCategory;