import React from "react";
import Logo from '../images/wow-logo-1.jpg';
import { useEffect, useState } from "react";
import {AboutSection, AboutTony, AboutViktor} from "../components/AboutSect";
import Carousel from "../components/Carousel";
import Fade from 'react-reveal/Fade';
import PortfolioCategory from "../components/PortfolioCategory";
import {Swiper, SwiperSlide} from "swiper/react";
import { Mousewheel,Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "../styles.css";


const Hem = () => {
  const [nameInput, setNameInput] = useState('DITT NAMN HÄR');
    
  
// Parallax on scroll
const [offsetY, setOffsetY] = useState(0);
const handleScroll = () => setOffsetY(window.pageYOffset);
useEffect(() => {
   window.addEventListener('scroll', handleScroll);
   return () => window.removeEventListener('scroll', handleScroll);
}, []);



return (<>
    <div className="hem">
         
        <section className="sect-1">
       
            <div className="logo-hero">
                <img src={Logo} />
            </div>
            <Fade>
      <h3 className="hero-subtitle">Fullservice reklambyrå med det lilla extra</h3>
      <h1 className="hero-title">Vi antar den skepnad uppdraget kräver</h1></Fade>
      <input type="text" className="input-name-here" placeholder="DITT NAMN HÄR" onChange={e => { setNameInput(e.target.value); }} />
      <Carousel nameInput={nameInput} />
      </section>
    <section className="not-ordinary">
      <div
        className="parallax-elements element-1"
        style={{ transform: `translateY(${offsetY * 0.2}px)` }}
      ><p>{nameInput}</p></div>
 <Fade>     
      <AboutSection />
      </Fade>
      </section> 
      <Fade>
        
      <div className="custom-test">
      <Swiper
        direction={"vertical"}
        mousewheel={true}
        
        pagination={{
          clickable: true,
        }}
        modules={[Mousewheel, Pagination]}
        className="mySwiper1"
      ><SwiperSlide><div className="holder">
        <div className="text-holder"><h1>WEB</h1><p>Description</p></div>
        <div className="content-holder">
<PortfolioCategory category="web" />
</div></div>
</SwiperSlide><SwiperSlide><div className="holder">
        <div className="text-holder"><h1>Branding</h1><p>Description</p></div>
        <div className="content-holder">
<PortfolioCategory category="branding" />
</div></div>
</SwiperSlide>
<SwiperSlide><div className="holder">
        <div className="text-holder"><h1>Print</h1><p>Description</p></div>
        <div className="content-holder">
<PortfolioCategory category="print" />
</div></div>
<a href="#about-tony" className="anchor-scrollto">LINK</a>
</SwiperSlide></Swiper>

</div>
  
</Fade>


  <section style={{position: "relative"}}>   
 
  <div className="parallax-elements element-2"
        style={{ transform: `translateY(${offsetY * 0.25}px)` }}
      ><p>{nameInput}</p><p>{nameInput}</p>
      </div>
  <Fade><AboutTony /></Fade>
</section>
<Fade>
<AboutViktor />
</Fade>
<Fade>
<section>

</section>
</Fade>
<Fade>
<section>
<h1>HÖR AV DIG</h1>

</section>
</Fade>
    </div>
    </>
  );
}

  export default Hem;