import React from "react";
import { Link } from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import { Mousewheel,Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "../styles.css";


const PortfolioItem = (props) => {
const categoryItems =  props.cases.map((item, index) =>
<div className="carousel" key={index}><SwiperSlide key={index} style={{backgroundImage:`url(${item.img})`}}>
<div className="blurred-title-holder">
<p>{item.name}</p>

<Link to={`/portfolio/${item.name}`} onClick={youBet}>Läs mer</Link>
</div>
</SwiperSlide>
</div>
   );
    

   return (
    <div style={{height:"100%"}}>
     <Swiper
          direction={"horizontal"}
      slidesPerView={2}
      spaceBetween={30}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
      className="carousel-slider"
    >{categoryItems}</Swiper>
    </div>
   )

}

const youBet = () => {
  window.scrollTo(0, 0);
}

export default PortfolioItem;