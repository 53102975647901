import React from "react";
import Fade from 'react-reveal/Fade';
const OmOss = () => (
    <div className="omoss">
      <section className="sect-omoss"><Fade>

 
      <h1>OM OSS</h1>
      </Fade>
      </section>
    </div>
   
  );

  export default OmOss;