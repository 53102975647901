import React from "react";
import 'offcanvas/offcanvas.css';
import 'offcanvas';
import '../styles.css';
import { Link } from "react-router-dom";
import Logo from '../images/wow-logo-1.jpg';

const MobileMenu = () => (
<div id="mobile-left" className="offcanvas left">
  <div className="m-m-header">
  <div className="m-m-logo"><img src={Logo} /></div><h3>WOW REKLAMBYRÅ</h3></div>

 <nav>
  <Link to="/">Home</Link> 
  <Link to="om-oss">Om Oss</Link>
  <Link to="portfolio">Portfolio</Link>
  <Link to="kontakt">Kontakt</Link>

</nav>
  
</div>

)

export default MobileMenu;
