import React, { Component } from 'react';
import "../layout/Carouselstyle.css";
class Carousel extends Component {
   

      
   
     

      componentDidMount() {
       
   
      var carousel = document.querySelector('.carousel');
      var cells = carousel.querySelectorAll('.carousel__cell');
      var cellCount; // cellCount set from cells-range input value
      var selectedIndex = 0;
      var cellWidth = carousel.offsetWidth;
      var cellHeight = carousel.offsetHeight;
      var isHorizontal = true;
      var rotateFn = 'rotateY';
      var radius, theta;
  
      // console.log( cellWidth, cellHeight );

      function rotateCarousel() {
        var angle = theta * selectedIndex * -1;
        carousel.style.transform = 'translateZ(' + -radius + 'px) ' + 
          rotateFn + '(' + angle + 'deg)';
          for ( var i=0; i < cells.length; i++ ) {
            var cell = cells[i];
            var cellAngle = theta * i;
            var customAngle = Math.abs(angle);
            cell.style.opacity = 0;
          console.log(cellAngle);
          if (customAngle === cellAngle) {
            
            console.log("MATCH" + cellAngle);
            cell.style.opacity = 1;

            

          }
          if ( customAngle === cellAngle - 72) {

            cell.style.opacity = 0.2;
          }

          if  (customAngle === cellAngle + 72) {
            cell.style.opacity = 0.2;
          }
          }
   

      }
      
      var prevButton = document.querySelector('.previous-button');
      prevButton.addEventListener( 'click', function() {
        selectedIndex--;
        rotateCarousel();
      });
      
      var nextButton = document.querySelector('.next-button');
      nextButton.addEventListener( 'click', function() {
        selectedIndex++;
        rotateCarousel();
      });
      

   
     
      
      
      
      function changeCarousel() {
        cellCount = 6;
        theta = 360 / cellCount;
        var cellSize = isHorizontal ? cellWidth : cellHeight;
        radius = Math.round( ( cellSize / 2) / Math.tan( Math.PI / cellCount ) );
        for ( var i=0; i < cells.length; i++ ) {
          var cell = cells[i];
          if ( i < cellCount ) {
            // visible cell
            cell.style.opacity = 1;
            var cellAngle = theta * i;
            cell.style.transform = rotateFn + '(' + cellAngle + 'deg) translateZ(' + radius + 'px)';
           
          } else {
            // hidden cell
            cell.style.opacity = 0;
            cell.style.transform = 'none';
          }
        }
      
        rotateCarousel();
      }
      
      
      
      function onOrientationChange() {
       
        isHorizontal = true;
        changeCarousel();
      }
      
      // set initials
      onOrientationChange();
      
    }
    
    render() {
        return (
          
  
          <>   
          
   
            
          
            <p>
     
    </p>

<div>
    
  <div className="scene">
    <div className="carousel">
      <div className="carousel__cell"><p className='firstcell'>{this.props.nameInput}</p><p className='firstcell-2'>{this.props.nameInput.split('/\b()/g').reduce((response,word)=> response+=word.slice(0,1),'')}</p></div>
      <div className="carousel__cell"><p className="secondcell-1">{this.props.nameInput}</p><p className="secondcell-2">{this.props.nameInput.split('/\b()/g').reduce((response,word)=> response+=word.slice(0,1),'')}</p></div>
      <div className="carousel__cell"><p>{this.props.nameInput}</p><p>{this.props.nameInput.split('/\b()/g').reduce((response,word)=> response+=word.slice(0,1),'')}</p></div>
      <div className="carousel__cell"><p>{this.props.nameInput}</p><p>{this.nameInput}</p></div>
      <div className="carousel__cell"><p>{this.props.nameInput}</p><p>{this.props.nameInput.split('/\b()/g').reduce((response,word)=> response+=word.slice(0,1),'')}</p><p>{this.props.nameInput.split('/\b()/g').reduce((response,word)=> response+=word.slice(0,1),'')}</p></div>
      
      
    </div>
   
  </div> <div className="carousel-scene-holder"><button className="previous-button"></button>
  <button className="next-button"></button></div>

          </div>
          

          </>
        );
      }
    }
    
    export default Carousel;