import React from "react";
import { Fade } from "react-reveal";
const Kontakt = () => (
    <div className="kontakt">
      <section className="sect-kontakt"><Fade>
      <h1>KONTAKT</h1>
      </Fade>
      </section>
    </div>
  );

  export default Kontakt;