import { render } from "@testing-library/react";
import React from "react";
import { useParams } from "react-router-dom";
import { data } from "./Data";
const PortfolioSingle = (props) => {
    const { name } = useParams();




const selected = data.filter(item => item.name === name).map(filteredPerson => ( <> 
    <div key={name} className="info">
    <p className="pre-header">KUNDCASE</p>
    <h1>{filteredPerson.name}</h1>
    <p className="descr">{filteredPerson.description}</p> 
  <div className="more">
    <a href="#" className="links">LÄNK</a><a href="#" className="links-other">LÄS MER</a>
  </div>
  </div>
     <div className="selected-image">
        <img src={filteredPerson.img} /></div>
        </>
))



const defaultItem =
 data.filter(item => item.name === "GastronomiLeverantören Hemsida").map(filteredPerson => (  
    <><div key="name" className="info">
        <p className="pre-header">KUNDCASE</p>
    <h1>{filteredPerson.name}</h1>
  <p className="descr">{filteredPerson.description}</p>  <div className="more">
    <a href="#" className="links">LÄNK</a><a href="#" className="links-other">LÄS MER</a>
  </div>
  </div> 
    <div className="selected-image" style={{backgroundImage:`url(${filteredPerson.img})`}}>
        <div className="content">Test</div></div>
        </>
       
))


 return (<>
{(() => { 
    if (name == null) {
        return(
<div className="portfolio-selected">{defaultItem}</div>
        )
    } else {
        return (
<div className="portfolio-selected"> {selected}</div>
        )
    }
})()}
    </>
    )
 
}





export default PortfolioSingle;