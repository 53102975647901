
export const data =  [
    {model:"Sentra", doors:4, category: "web", name: "GastronomiLeverantören Hemsida",  
    img: "https://www.gastronomileverantoren.se/wp-content/uploads/2022/07/Gastro_start-1.jpg",  
    description: "Komplett hemsida med e-handelsfunktion för inloggade kunder. Hemsidan har cirka 1300 produkter och har ett komplett handelsflöde för kunderna. Byggd med Wordpress och Bootstrap. Optimerad för snabb webb-visning och sökmotoroptimering."

},
    {model:"Sentra", doors:4, category: "web", name: "Louize",  img: "https://picsum.photos/200/300"},
    {model:"Sentra", doors:4, category: "web", name: "Elatra",  img: "https://picsum.photos/200/300"},
    {model:"Maxima", doors:4, category: "print", name: "Tony",  img: "https://picsum.photos/200/300"},
    {model:"Maxima", doors:4, category: "print", name: "Kattis",  img: "https://picsum.photos/200/300"},
    {model:"Skyline", doors:2, category: "branding", name: "Jenny",  img: "https://picsum.photos/200/300"},
    {model:"Skyline", doors:2, category: "branding", name: "Leif",  img: "https://picsum.photos/200/300"},
    {model:"Skyline", doors:2, category: "branding", name: "Moa",  img: "https://picsum.photos/200/300"},
    {model:"Skyline", doors:2, category: "branding", name: "Mattis",  img: "https://picsum.photos/200/300"},
]

