import React, { Component } from 'react';
import '../styles.css';
import {Router, Link } from "react-router-dom";
import Logo from '../images/wow-logo-1.jpg';
import { useState } from "react";
import Hamburger from '../images/hamburger.svg';
class Header extends Component {

   componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 20) {
      document.querySelector(".header").className = "header scroll";
    } else {
      document.querySelector(".header").className = "header";
    }
  }

  

  render() {
return(


<div className="header">
<div className="buttons hamburger-button" data-offcanvas-fixed>
    <button onClick={handleClick}>
      <img src={Hamburger}/>
      </button>
    
  </div>
  <div className="logo"><img src={Logo} /></div>
<nav className="main-menu">
 
  <Link to="/">Hem</Link> 
  <Link to="om-oss">Om Oss</Link>
  <Link to="portfolio">Portfolio</Link>
  <Link to="kontakt">Kontakt</Link>

</nav>
<div className="contact-us-btn">
  <button><span>KONTAKTA OSS IDAG</span></button>
</div>

</div>


)

  }

}

const handleClick = () => {
  const menu = document.getElementById('mobile-left')
   // 👇️ toggle class on click
   menu.classList.toggle('active');
  };


export default Header;

