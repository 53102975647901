import React from "react";

import "./styles.css"
import Header from "./layout/Header";
import ReactDOM from 'react-dom/client';
import Portfolio from "./pages/Portfolio";
import 'offcanvas/offcanvas.css';
import 'offcanvas';
import MobileMenu from "./layout/MobileMenu";
import Hamburger from './images/hamburger.svg';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PortfolioCategory from "./components/PortfolioCategory";

import PortfolioSingle from "./components/PortfolioSingle";
import Hem from "./pages/Hem";
import OmOss from "./pages/OmOss";

import Kontakt from "./pages/Kontakt";
import ScrollToTop from "./components/scrollToTop";




const root = ReactDOM.createRoot(document.getElementById('root'));


const App = () => {


  const handleClick = () => {
  const menu = document.getElementById('mobile-left')
   // 👇️ toggle class on click
   menu.classList.toggle('active');
  };


  return(
<>
<div className="main">



 <div className="buttons hamburger-button" data-offcanvas-fixed>
    <button onClick={handleClick}>
      <img src={Hamburger}/>
      </button>
    
  </div>


</div>
</>

  )}




root.render(

  <BrowserRouter>
  <MobileMenu />
<Header />
  <Routes> 
    <Route exact path="/" element={<Hem />} />
      <Route path="om-oss" element={<OmOss />} />
    <Route path="portfolio" element={<Portfolio />}>
          <Route path=":portfolioCategory" element={<PortfolioCategory />} />
       
          <Route path=":name/" element={<PortfolioSingle />}/>
          
    </Route>
    <Route path="kontakt" element={<Kontakt />} />
  </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

