import React from "react";
import "../styles.css";
import Tony from "../images/tony.png";
import Viktor from "../images/viktor.png";

export const AboutSection = () => {

   return (
       
        <div className="about">
           
    <h1>WOW ÄR INTE EN VANLIG REKLAMBYRÅ</h1>
 
    <div className="text">Nej, vi är en dynamisk sådan som ändrar skepnad beroende på uppdrag. 
    Hur då?, undrar ni. Det är inte kappans fel åt vilket håll vinden blåser? 
    Nja, inte riktigt så – rätt tänkt, men helt fel!</div></div>
   );
};

export const AboutTony = () => {
return (
    <div className="about-wrapper" id="about-tony">


    <div className="about-bio">
        <div className="about-title">
<h1>Tony Nilsson</h1>
<h2>ART DIRECTOR - GRAFISK DESIGNER - TUSENKONSTNÄR</h2>
        </div>
        <p className="descr">
        Om Marie är tusenkonstnären och problemlösaren, så är Tony konstnären och kreatören. Huvud-sysslan är som AD och illustratör. Men, på en ”liten” byrå får man arbeta med allt. Så mellan illustrations/ designuppdragen blir det även en del skrivande och strategiskt arbete. En bra beskrivning av Tony är ”en prestigelös kreativ grovarbetare” som brinner för sitt arbete. Arbetet är mer än ett arbete för honom – det är en passion. Parallellt med arbetet på byrån när han ett alter-ego som konstnär med egen ateljé. Då är det Tony ”utan skyddsnät” som gäller. 
        </p>
        <div className="strength-weakness">
            <div className="strength">
                <p className="descr">
                En av dina styrkor, behöver inte vara en lång text
                </p>
            </div>
            <div className="strength">
                <p className="descr">
                En av dina styrkor, behöver inte vara en lång text
                </p>
            </div>
            <div className="weakness">
                <p className="descr">
                En av dina styrkor, behöver inte vara en lång text
                </p>
            </div>
            <div className="weakness">
                <p className="descr">
                En av dina styrkor, behöver inte vara en lång text
                </p>
            </div>
        </div>
        </div>
        <div className="about-image">
<img src={Tony} />

        </div>
        </div>
    
);
}


export const AboutViktor = () => {
    return (
        <section><div className="about-wrapper">
    <div className="about-image invert">
    <img src={Viktor} />
            </div>
        <div className="about-bio">
   
            <div className="about-title">
    <h1>Tony Nilsson</h1>
    <h2>ART DIRECTOR - GRAFISK DESIGNER - TUSENKONSTNÄR</h2>
            </div>
            <p className="descr">
            Om Marie är tusenkonstnären och problemlösaren, så är Tony konstnären och kreatören. Huvud-sysslan är som AD och illustratör. Men, på en ”liten” byrå får man arbeta med allt. Så mellan illustrations/ designuppdragen blir det även en del skrivande och strategiskt arbete. En bra beskrivning av Tony är ”en prestigelös kreativ grovarbetare” som brinner för sitt arbete. Arbetet är mer än ett arbete för honom – det är en passion. Parallellt med arbetet på byrån när han ett alter-ego som konstnär med egen ateljé. Då är det Tony ”utan skyddsnät” som gäller. 
            </p>
            <div className="strength-weakness">
                <div className="strength">
                    <p className="descr">
                    En av dina styrkor, behöver inte vara en lång text
                    </p>
                </div>
                <div className="strength">
                    <p className="descr">
                    En av dina styrkor, behöver inte vara en lång text
                    </p>
                </div>
                <div className="weakness">
                    <p className="descr">
                    En av dina styrkor, behöver inte vara en lång text
                    </p>
                </div>
                <div className="weakness">
                    <p className="descr">
                    En av dina styrkor, behöver inte vara en lång text
                    </p>
                </div>
            </div>
            </div>
          
            </div>
        </section>
    );
    }

